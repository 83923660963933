import React from "react";
import { useState, useEffect, createContext } from "react";
import { Button, InputNumber, Image } from 'antd';


function TestClass() {
	
	const [visible, setVisible] = useState(false);
	const [scaleStep, setScaleStep] = useState(0.5);
	const [url, setUrl] = useState('');

	const previewHandler = () => {
		setUrl("https://lianke-1306244544.cos.ap-shanghai.myqcloud.com/be4eebf3-2811-40d2-91b4-fec9fa691cf2/122.jpeg");
		setVisible(true);
	}

	return (
		<>
			<div>
				scaleStep:{' '}
				<InputNumber
					min={0.1}
					max={5}
					defaultValue={0.5}
					step={0.1}
					onChange={(val) => setScaleStep(val)}
				/>
			</div>
			<br />
			<Button type="primary" onClick={previewHandler}>
				show image preview
			</Button>
			<Image
				width={200}
				style={{
					display: 'none',
				}}
				src={url}
				preview={{
					visible,
					scaleStep,
					src: url,
					onVisibleChange: (value) => {
						setVisible(value);
					},
				}}
			/>
		</>
	);
};

export default TestClass;
import './App.less';
import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/login';
import ResourcePage from './pages/resource';
import Register from './pages/register';
import TestPage from './pages/test';
import LayoutPage from './pages/layout';
import Home from "./pages/home";
import Notes from "./pages/note";
import Images from './pages/image';
import { AuthComponent } from "./components/AuthComponent";


function App() {
  return (
    <BrowserRouter>
      <div className="App">
		<header className='App-header'>
          {/* <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a> */}
          <Routes>
            <Route
              path="/resource"
              element={
				  <AuthComponent>
					  <ResourcePage />
				  </AuthComponent>
			  	}>
            </Route>
			<Route
			path="/"
			element={ <LayoutPage /> }
			>
				<Route index element={ <Home /> } />
				<Route path='notes' element={ <Notes /> } />
				<Route path='images' element={ <Images /> } />
			</Route>
			<Route path='/test' element={ <TestPage /> }></Route>
            <Route path='/login' element={ <Login /> }></Route>
            <Route path='/register' element={ <Register />}></Route>
			{/* <Route path='/layout' element= { <LayoutPage /> }></Route> */}
          </Routes>
        </header>
      </div>
    </BrowserRouter>
  );
}

export default App;

const key = 'token_key'

const setToken = (token) => {
    window.localStorage.setItem(key, token);
};

const getToken = () => {
    return window.localStorage.getItem(key);
};

const deleteToken = () => {
    window.localStorage.removeItem(key);
};

export {
    setToken,
    getToken,
    deleteToken
};
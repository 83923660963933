import React, { useEffect, useState } from "react";
import {
	Button,
	Card,
	Form,
	Input,
	message,
	List,
	Popconfirm
} from 'antd';
import { useStore } from "../../store";
import { observer } from "mobx-react-lite";


const { TextArea } = Input;


function Notes() {

	const [form] = Form.useForm();
	const { resourceStore } = useStore();
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(10);

	// get notes
	useEffect(
		() => {
			async function getCurrentNotes() {
				await resourceStore.getNote();
				// console.log(resourceStore.notes);
				setData(resourceStore.notes);
				setTotal(resourceStore.count);		
			}
			getCurrentNotes();
		}, [resourceStore]
	);

	const onFinish = async (values) => {
		// console.log('Success:', values);
		await resourceStore.addNote(values);
		setData(resourceStore.notes);
		setTotal(resourceStore.count);		
		// form.setFieldsValue(
		// 	{
		// 		content: resourceStore.note
		// 	}
		// );
		form.resetFields();
		message.success('success added.');
	};
	const onFinishFailed = (errorInfo) => {
		// console.log('Failed:', errorInfo);
		message.error("update failed.");
	};

	const onConfirmHandler = async () => {
		await resourceStore.deleteAllNote();
		setData(resourceStore.notes);
		setTotal(resourceStore.count);		
		message.success('delete success.');
	};

	const onChangePageHandler = (page) => {
		async function getCurrentNotes() {
			await resourceStore.getNote(page);
			// console.log(resourceStore.notes);
			setData(resourceStore.notes);
			setTotal(resourceStore.count);
		}
		getCurrentNotes();

	};

	const onDeleteNoteHandler = (id) => {
		async function deleteNote() {
			await resourceStore.deleteNote(id);
			setData(resourceStore.notes);
			setTotal(resourceStore.count);
		}
		deleteNote();
	};

	return (
		<div>
			<Form
				form={form}
				name="basic"
				wrapperCol={{
					span: 16,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item
					name="content"
					rules={[
						{
							required: true,
							message: 'Please input content!'
						},
						{
							type: String
						},
						{
							whitespace: true
						}
					]}
				>
					<TextArea rows={2} />
				</Form.Item>
				<Form.Item
					wrapperCol={{
						offset: 8,
						span: 16,
					}}
				>
					<Button type="primary" htmlType="submit">
						Add
					</Button>
				</Form.Item>
			</Form>

			<Popconfirm
				title="Are you sure to delete ALL?"
				onConfirm={onConfirmHandler}
				okText="Yes"
				cancelText="No"
			>
				<Button
					danger
					type="primary"
				>
					DELETE ALL
					</Button>
			</Popconfirm>	
			<List
				itemLayout="vertical"
				grid={{
					gutter: 16,
					xs: 1,
					sm: 2,
					md: 4,
					lg: 4,
					xl: 6,
					xxl: 3,
				}}
				pagination={{
					position: "bottom",
					defaultCurrent: 1,
					pageSize: 10,
					responsive: true,
					total: total,
					onChange: onChangePageHandler
				}}
				dataSource={data}
				renderItem={(item) => (
					<List.Item>
						<Card
						 title={item.datetime}
						 extra={<button onClick={() => onDeleteNoteHandler(item.id)}>X</button>}
						 >{ item.content }</Card>
					</List.Item>
				)}
			/>			
		</div>
	)
}


export default observer(Notes);
import { useStore } from "../../store";
import { observer } from "mobx-react-lite";


function Home() {
	const { userStore } = useStore();

	return (
		<div>
			<span>Welcome {userStore.userInfo.username}</span>
			<p>Email: {userStore.userInfo.email}</p>
		</div>
	)
}

export default observer(Home);
import { makeAutoObservable } from "mobx";
import { http } from "../utils";


class ResourceStore {
	notes = [];
	count = 10;
	// resources = [];
	constructor() {
		makeAutoObservable(this);
	}

	getNote = async (page_no=1) => {
		const res = await http.get('/note', {
			params: {
				page: page_no
			}
		});
		this.notes = res.data.notes;
		this.count = res.data.count;
	};

	updateNote = async ({ content }) => {
		const res = await http.post('/note', {
			content
		});
		this.notes = res.data.content;
		this.count = res.data.count;
	};

	addNote = async ({ content }) => {
		const res = await http.put('/note', {
			content
		});
		this.notes = res.data.notes;
		this.count = res.data.count;
	};

	deleteAllNote = async () => {
		const res = await http.delete('/note');
		this.count = res.data.count;
		this.notes = res.data.notes;
	}

	deleteNote = async (id) => {
		const res = await http.delete('/note/' + id.toString());
		this.count = res.data.count;
		this.notes = res.data.notes;
	}

	async getResources() {
		const res = await http.get('/resource');
		return res.data.data
	};

	async getPresignUrl(fileName) {
		const res = await http.get('/resource/sign', {
			params: {
				name: fileName
			}
		});
		// console.log(res.data.data);
		return res.data.data;
	}
}

export default ResourceStore;
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Form, Input, message } from 'antd';
import './index.scss';
import { useStore } from "../../store";
import { useNavigate } from "react-router-dom";


function Login() {
    const { loginStore } = useStore();
    const navigate = useNavigate();

    const onFinish = async (values) => {
        // console.log('Success:', values);
		try {
			await loginStore.getToken(values);
			navigate('/', { replace: true });
			message.success('Login success!');			
		} catch (error) {
			message.error('Login failed!', 3);
		}

    };
    
    const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
		message.error('Login failed!', errorInfo);
    };

  return (
      <Card className='login-container'>
        <Form
            name="normal_login"
            className="login-form"
            size='large'
            initialValues={{
                remember: true,
            }}
            onFinish={ onFinish }
            onFinishFailed = { onFinishFailed }
            validateTrigger={ ['onBlur', 'onChange'] }
            >
            <Form.Item
                name="username"
                rules={[
                {
                    required: true,
                    message: 'Please input your Username!',
                },
                {
                    max: 10,
                    message: 'Please input correct username!',
                    validateTrigger: ['onBlur', 'onChange']
                }
                ]}
            >
                <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                {
                    required: true,
                    message: 'Please input your Password!',
                },
                {
                    max: 20,
                    message: 'Please input correct password!',
                    validateTrigger: 'onBlur'
                }
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>
            <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="https://www.lianke.com">
                Forgot password
                </a>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
                </Button>
                <a>   or    </a>
                <a href="/register">REGISTER NOW!</a>
            </Form.Item>
        </Form>
      </Card>
  );
};

export default Login;
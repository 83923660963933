import { makeAutoObservable } from "mobx";
import { http, setToken } from "../utils";


class LoginStore {
    token = "";
    constructor() {
        makeAutoObservable(this);
    }
    getToken = async ({ username, password }) => {
        const res = await http.post('/user/login', {
            username, password
        });
        this.token = res.data.token;
        setToken(this.token);
        // console.log(this.token);
    };
}

export default LoginStore;
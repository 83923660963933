import axios from "axios";
import { getToken } from "./token";


const http = axios.create(
    {
        baseURL: process.env.REACT_APP_BASE_URL,
		// baseURL: 'http://43.128.119.103:5000/api/v1',
		// baseURL: 'http://120.26.42.106:5000/api/v1',
        timeout: 10000
    }
);

http.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token && token !== 'undefined') {
            config.headers.Authorization = `Bearer ${ token }`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
	(response) => {
		return response;
	}, (error) => {
		if (error.response.status === 401) {
			window.location.href = 'login';
		}
		return Promise.reject(error);
	}
);

export { http };
import { makeAutoObservable } from "mobx";
import { http, setToken } from "../utils";


class RegisterStore {
    token = "";
    constructor() {
        makeAutoObservable(this);
    }
    register = async ({ username, password, email, confirm, invitecode}) => {
        const res = await http.post('/user/register', {
            username,
            password,
            email,
            confirm,
			invitecode
        });
        this.token = res.data.token;
        setToken(this.token);
    };
}

export default RegisterStore;
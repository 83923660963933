import { makeAutoObservable } from "mobx";
import { http } from "../utils";


class UserStore {
	userInfo = {};
	constructor() {
		makeAutoObservable(this);
	}
	getUserInfo = async () => {
		const res = await http.get('/user/info');
		this.userInfo = res.data.user;
		// console.log(res);
	};
}

export default UserStore;
import LoginStore from "./login.store";
import RegisterStore from "./register.store";
import ResourceStore from "./resource.store";
import React from "react";
import UserStore from "./user.store";


class RootStore {
    constructor() {
        this.loginStore = new LoginStore();
        this.registerStore = new RegisterStore();
		this.resourceStore = new ResourceStore();
		this.userStore = new UserStore();
    }
}

const rootStore = new RootStore();
const context = React.createContext(rootStore);
const useStore = () => React.useContext(context);

export { useStore };
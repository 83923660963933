import {
    Button,
    Checkbox,
    Form,
    Input,
    Card,
    message
} from 'antd';
import './index.scss';
import { useStore } from "../../store";
import { useNavigate } from "react-router-dom";


const formItemLayout = {
    labelCol: {
        xs: {
        span: 24,
        },
        sm: {
        span: 8,
        },
    },
    wrapperCol: {
        xs: {
        span: 24,
        },
        sm: {
        span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
        span: 24,
        offset: 0,
        },
        sm: {
        span: 16,
        offset: 8,
        },
    },
};
  
function Register() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { registerStore } = useStore();

    const onFinish = async (values) => {
        // console.log('Received values of form: ', values);
        await registerStore.register(values);
        navigate('/', { replace: true });
        message.success('Login success!');
    };

    return (
        <Card className='register-container'>
            <Form
                {...formItemLayout}
                form={form}
                size="large"
                labelAlign='left'
                labelWrap="true"
                name="register"
                onFinish={onFinish}
                initialValues={{
                    residence: ['zhejiang', 'hangzhou', 'xihu'],
                    prefix: '86',
                }}
                scrollToFirstError
            >
            <Form.Item
                name="email"
                label="E-mail"
                rules={[
                {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                },
                {
                    required: true,
                    message: 'Please input your E-mail!',
                },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="password"
                label="Password"
                rules={[
                {
                    required: true,
                    message: 'Please input your password!',
                },
                ]}
                hasFeedback
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                {
                    required: true,
                    message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }

                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                }),
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="username"
                label="Username"
                tooltip="Your account name"
                rules={[
                {
                    required: true,
                    message: 'Please input your username!',
                    whitespace: true,
                },
                ]}
            >
                <Input />
            </Form.Item>

			<Form.Item
				name="invitecode"
				label="Invite Code"
				// tooltip="Your account name"
				rules={[
					{
						required: true,
						message: 'Please input the invite code!',
						whitespace: true,
					},
				]}
			>
				<Input />
			</Form.Item>

            <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                {
                    validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                },
                ]}
                {...tailFormItemLayout}
            >
                <Checkbox>
                I have read the <a href="/">agreement</a>
                </Checkbox>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                Register
                </Button>
            </Form.Item>
            </Form>
        </Card>
    );
};

export default Register;
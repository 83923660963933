import React, { useEffect, useState } from "react";
import {
	Table,
	Space,
	Button,
	message,
	Upload,
	Image
} from 'antd';
import { UploadOutlined } from "@ant-design/icons";
import { http } from "../../utils";


function Images() {
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);
	const [resources, setResources] = useState([]);
	const [visible, setVisible] = useState(false);
	const [url, setUrl] = useState('');
	const [scaleStep, setScaleStep] = useState(0.5);
	const [total, setTotal] = useState(10);

	// Table
	const columns = [
		{
			title: 'Name',
			dataIndex: 'resource_name',
			key: 'resource_name',
			render: (text) => <a>{text}</a>
		},
		{
			title: 'Date',
			dataIndex: 'datetime',
			key: 'datetime'
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<a onClick={() => onDownload(record)}>Download</a>
					<a onClick={() => onPreview(record)}>Preview</a>
					<a onClick={() => onDelete(record)}>Delete</a>
				</Space>
			)
		}
	];
	// get resource
	const getResources = async (page_no=1) => {
		const res = await http.get('/resource', {
			params: {
				page: page_no
			}
		});
		setResources(res.data.data);
		setTotal(res.data.count);
	};
	useEffect(() => {
		getResources();
	}, []);

	const getDownloadUrl = async (fileName) => {
		const res = await http.get("/resource/download", {
			params: {
				name: fileName
			}
		});
		return res.data.data.download_url;
	}

	const removeResource = async (id) => {
		const res = await http.delete("/resource/" + id);
		return res.data.data
	}

	const onDownload = (record) => {
		let obj = getDownloadUrl(record.resource_name).then(
			data => { return data; }
		);
		obj.then(function (result) {
			// http.get(result);
			const download_header = '&response-content-disposition=attachment';
			window.location.href = result + download_header;
		});
		// console.log(obj);

	};

	const onDelete = (record) => {
		let obj = removeResource(record.id.toString()).then(
			data => { return data; }
		);
		obj.then(function (result) {
			setResources(result);
		});
	};

	const onPreview = (record) => {
		const imageTypes = ['image/jpeg', 'image/png'];
		if (imageTypes.includes(record.content_type)) {
			setUrl(record.resource_url);
			setVisible(true);
		} else {
			message.warn('only images support preview');
		}

		// console.log('preview', record);
	}

	const onChangePageHandler = (page) => {
		getResources(page);
	}

	const getPresignUrl = async (fileName) => {
		const res = await http.get('/resource/sign', {
			params: {
				name: fileName
			}
		});
		return res.data.data;
	};

	const handleUpload = () => {
		// const formData = new FormData();
		// fileList.forEach((file) => {
		// 	formData.append('files[]', file);
		// });
		setUploading(true);

		fileList.forEach((file) => {
			// console.log(file);
			const formData = new FormData();
			formData.append('file', file);

			let obj = getPresignUrl(file.name).then(data => { return data });
			obj.then(function (result) {
				const { sign_url, object_url } = result;
				fetch(sign_url, {
					method: 'PUT',
					body: file,
					headers: {
						'Content-Type': file.type
					}
				})
					.then((res) => res.status)
					.then(async () => {
						const res =	await http.put('/resource', {
							'resource_name': file.name,
							'resource_url': object_url,
							'content_type': file.type
						});
						setResources(res.data.data);
						// setFileList(fileList.shift());
						message.success(`upload ${file.name} successfully.`);
					})
					.catch((reason) => {
						console.log(reason);
						message.error('upload failed.');
					})
					.finally(() => {
						setFileList([]);
						setUploading(false);
						// getResources();
						// window.location.href('/images');
					});
			});
		});
	};

	const props = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			setFileList([...fileList, file]);
			return false;
		},
		fileList,
	};

	return (
		<>
			<Table
				columns={columns}
				dataSource={resources}
				rowKey={resource => resource.resource_name}
				pagination={{
					defaultCurrent:1,
					pageSize: 10,
					responsive: true,
					total: total,
					onChange: onChangePageHandler
				}}
			/>
			<Upload {...props}>
				<Button icon={<UploadOutlined />}>Select File</Button>
			</Upload>
			<Button
				type="primary"
				onClick={handleUpload}
				disabled={fileList.length === 0}
				loading={uploading}
				style={{
					marginTop: 16,
					width: 120
				}}
			>
				{uploading ? 'Uploading' : 'Start Upload'}
			</Button>
			<Image
				width={200}
				style={{
					display: 'none',
				}}
				src={url}
				preview={{
					visible,
					scaleStep,
					src: url,
					onVisibleChange: (value) => {
						setVisible(value);
					},
				}}
			/>
		</>
	);
}


export default Images;

import { Navigate } from "react-router-dom";
import { getToken } from "../utils";


function AuthComponent({ children }) {
    const isAuth = getToken();
	if (isAuth == "undefined") {
		return <Navigate to="/login" replace />
	}

    return isAuth ?
     <>{ children }</> :
     <Navigate to="/login" replace />;
}

export { AuthComponent };
import React, { useEffect, useState } from "react";
import {
	Table,
	Tag,
	Space,
	Input,
	Button,
	Form,
	message,
	Upload
} from 'antd';
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { useStore } from "../../store";
import { http } from "../../utils";


const { TextArea } = Input;
const { Dragger } = Upload;


function Note() {

	const [form] = Form.useForm();
	const { resourceStore } = useStore();

	// get note
	useEffect(
		() => {
			async function getCurrentNote() {
				await resourceStore.getNote();
				form.setFieldsValue(
					{
						content: resourceStore.note
					}
				);
			}
			// resourceStore.getNote();
			getCurrentNote();
		},
		[form]
	);


	const onFinish = async (values) => {
		// console.log('Success:', values);
		await resourceStore.updateNote(values);
		form.setFieldsValue(
			{
				content: resourceStore.note
			}
		);
		message.success('update success.');
	};
	const onFinishFailed = (errorInfo) => {
		// console.log('Failed:', errorInfo);
		message.error("update failed.");
	};



	return (
		<div>
			<Form
				form={form}
				name="basic"
				wrapperCol={{
					span: 16,
				}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off"
			>
				<Form.Item name="content">
					<TextArea rows={4} />
				</Form.Item>
				<Form.Item
					wrapperCol={{
						offset: 8,
						span: 16,
					}}
				>
					<Button type="primary" htmlType="submit">
						Update
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

class UploadResource extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			name: 'file',
			method: 'put',
			multiple: false,
			action: 'https://api.lianke.games/',
			url: ''
		};
	}

	async getPresignUrl(fileName) {
		const res = await http.get('/resource/sign', {
			params: {
				name: fileName
			}
		});
		// console.log(res.data.data);
		return res.data.data;
	}

	onChange = (info) => {
		const { name, status } = info.file;
		this.kick(name);

		if (status !== 'uploading') {
			console.log(info.file, info.fileList);
		}

		if (status === 'done') {
			// tell backend
			http.put('/resource', {
				'resource_name': this.state.name,
				'resource_url': this.state.url
			});
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
			// console.log(this.state);
		}
	};

	onDrop = async (e) => {
		const fileName = e.dataTransfer.files[0].name;
		await this.kick(fileName);
		// console.log(this.state);
	};

	kick = async (fileName) => {
		const { sign_url, object_url } = await this.getPresignUrl(fileName);

		this.setState({
			action: sign_url,
			name: fileName,
			url: object_url
		}, () => {
			this.customRequest(this.state)
		});
	}

	customRequest = (state) => {
		console.log(state);
	};

	render() {
		return (
			<Dragger
			{ ...this.state }
			onChange={ this.onChange }
			onDrop={ this.onDrop }
			customRequest={ this.customRequest }
			>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">Click or drag file to this area to upload</p>
				<p className="ant-upload-hint">
					Support for a single or bulk upload. Strictly prohibit from uploading company data or other
					band files
				</p>
			</Dragger>
		);
	}
}

function Resource() {
	// Table
	const columns = [
		{
			title: 'Name',
			dataIndex: 'resource_name',
			key: 'resource_name',
			render: (text) => <a>{text}</a>
		},
		{
			title: 'Date',
			dataIndex: 'datetime',
			key: 'datetime'
		},
		{
			title: 'Action',
			key: 'action',
			render: (_, record) => (
				<Space size="middle">
					<a onClick={ () => onDownload(record) }>Download</a>
					<a onClick={ () => onDelete(record) }>Delete</a>
				</Space>
			)
		}
	];
	// get resource
	const [resources, setResources] = useState([]);
	const getResources = async () => {
		const res = await http.get('/resource');
		setResources(res.data.data);
	};
	useEffect(() => {
		getResources();
	}, []);

	const getDownloadUrl = async (fileName) => {
		const res = await http.get("/resource/download", {
			params: {
				name: fileName
			}
		});
		return res.data.data.download_url;
	}

	const removeResource = async (id) => {
		const res = await http.delete("/resource/" + id);
		return res.data.data
	}

	const onDownload = (record) => {
		let obj = getDownloadUrl(record.resource_name).then(
			data => { return data; }
		);
		obj.then(function (result) {
			// http.get(result);
			window.location.href = result;
		});
		// console.log(obj);

	};

	const onDelete = (record) => {
		let obj = removeResource(record.id.toString()).then(
			data => { return data; }
		);
		obj.then(function (result) {
			setResources(result);
		});
	};

	return (
		<Table
			columns={columns}
			dataSource={resources}
			rowKey={resource => resource.resource_name}
		/>
	);
}

function UploadResource1() {
	const [fileList, setFileList] = useState([]);
	const [uploading, setUploading] = useState(false);

	const getPresignUrl = async (fileName) => {
		const res = await http.get('/resource/sign', {
			params: {
				name: fileName
			}
		});
		return res.data.data;
	}

	const handleUpload = () => {
		const formData = new FormData();
		fileList.forEach((file) => {
			formData.append('files[]', file);
		});
		setUploading(true); // You can use any AJAX library you like

		for (let index = 0; index < fileList.length; index++) {
			const file = fileList[index];

			let obj = getPresignUrl(file.name).then(data => { return data });
			obj.then(function (result) {
				const { sign_url, object_url } = result;
				fetch(sign_url, {
					method: 'PUT',
					body: formData,
				})
					.then((res) => res.status)
					.then(() => {
						http.put('/resource', {
							'resource_name': file.name,
							'resource_url': object_url
						});
						setFileList(fileList.shift());
						message.success('upload successfully.');
					})
					.catch((reason) => {
						console.log(reason);
						message.error('upload failed.');
					})
					.finally(() => {
						setUploading(false);
					});
			});
		};
	}


	const props = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			setFileList([...fileList, file]);
			return false;
		},
		fileList,
	};
	return (
		<>
			<Upload {...props}>
				<Button icon={<UploadOutlined />}>Select File</Button>
			</Upload>
			<Button
				type="primary"
				onClick={handleUpload}
				disabled={fileList.length === 0}
				loading={uploading}
				style={{
					marginTop: 16,
				}}
			>
				{uploading ? 'Uploading' : 'Start Upload'}
			</Button>
		</>
	);
};


function ResourcePage() {
	return (
		<div>
			<Note />
			<UploadResource1 />
			<Resource />
		</div>
	);
}


export default ResourcePage;
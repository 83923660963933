import {
	FileTextOutlined, 
	UserOutlined, 
	HomeOutlined, 
	LogoutOutlined, 
	FileImageOutlined
} from '@ant-design/icons';
import { Layout, Menu, Popconfirm } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useStore } from "../../store";
import { deleteToken } from "../../utils";
import './index.scss';


const { Header, Content, Sider } = Layout;

const navigators = [
	{
		key: '/',
		icon: React.createElement(HomeOutlined),
		label: 'Home'
	},
	{
		key: '/notes',
		icon: React.createElement(FileTextOutlined),
		label: 'Notes'
	},
	{
		key: '/images',
		icon: React.createElement(FileImageOutlined),
		label: 'Images'
	},
	// {
	// 	key: '/me',
	// 	icon: React.createElement(UserOutlined),
	// 	label: 'Me'
	// }
];

function LayoutPage () {

	const [current, setCurrent] = useState('/');
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { userStore } = useStore();
	useEffect(() => {
		userStore.getUserInfo();
		setCurrent(pathname);
	}, [userStore]);


	const onClickMenu = ({ key }) => {
		setCurrent(key);
		navigate(key);
	};

	const onConfirmHandler = () => {
		deleteToken();
		navigate('/login', { replace: true });
	}

	return (
		<Layout>
			<Sider
				breakpoint="lg"
				collapsedWidth="0"
				onBreakpoint={(broken) => {
					console.log(broken);
				}}
				onCollapse={(collapsed, type) => {
					console.log(collapsed, type);
				}}
			>
				<div className="logo" />
				<Menu
					theme="dark"
					mode="inline"
					selectedKeys={[current]}
					items={ navigators }
					onClick={ onClickMenu }
				/>
			</Sider>
			<Layout>
				<Header
					className="site-layout-sub-header-background"
					style={{
						padding: 0,
					}}
				>
					<div className="user-info">
						<UserOutlined />
						<span> </span>
						<span className="user-name">{ userStore.userInfo.username }</span>
						<span className="user-logout">
							<Popconfirm
								// onConfirm={onConfirm}
								title="Confirm?" 
								okText="OK" 
								cancelText="Cancel" 
								onConfirm={ onConfirmHandler }
							>
								<LogoutOutlined /> Log out
							</Popconfirm>
						</span>
					</div>
				</Header>
				<Content
					style={{
						margin: '24px 16px 0',
					}}
				>
					<div
						className="site-layout-background"
						style={{
							padding: 24,
							minHeight: 360,
						}}
					>
						<Outlet />
					</div>
				</Content>
			</Layout>
		</Layout>
	)
};
export default observer(LayoutPage);